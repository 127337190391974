import React from "react"
import Tabs from "../Tabs"
import UserProfileStudentInfoSection from "./UserProfileStudentInfoSection"
import UserProfileProfileInfoSection from "./UserProfileProfileInfoSection"
import { useTranslation } from "react-i18next"

const UserProfileStudentSection = () => {
  const { t } = useTranslation()

  const data = [
    {
      heading: (
        <div className="f-ac f-js">
          <div className="f-ac chosen">
            <div className="user-profile-info-link active">
              {t("main.userProfileTabs.profileInfo")}
            </div>
          </div>
        </div>
      ),
      body: <UserProfileProfileInfoSection />,
    },
    {
      heading: (
        <div className="f-ac f-js">
          <div className="f-ac chosen">
            <div className="user-profile-info-link active">
              {t("main.userProfileTabs.studentInfo")}{" "}
            </div>
          </div>
        </div>
      ),
      body: <UserProfileStudentInfoSection />,
    },
  ]

  return (
    <>
      <div className="profile-info-wrapper container mt-5">
        <div className="row">
          <div className="user-profile-title mb-4 col ps-4">
            {t("main.userProfileTabs.myProfile")}{" "}
          </div>
        </div>
        <Tabs data={data} />
      </div>
    </>
  )
}

export default UserProfileStudentSection
