import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import "react-image-crop/dist/ReactCrop.css"
import ReactCrop from "react-image-crop"
import axios from "../http/axios"
import { useTranslation } from "react-i18next"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CropperPhoto = props => {
  const { t } = useTranslation()

  const [accountsApplications, setAccountsApplications] = useState({
    croppedImageUrl: "",
    open: false,
    src: null,
    crop: {
      width: 300,
      height: 300,
      aspect: 1,
    },
  })

  const [imageRef, setImageRef] = useState("")
  const [blob, setBlob] = useState()

  let fileInput = React.createRef()

  const handleClickOpen = () => {
    setAccountsApplications(prev => ({ ...prev, open: true }))
  }

  const handleClose = () => {
    setAccountsApplications(prev => ({ ...prev, open: false }))
  }
  function savePhotoUpdate() {
    fileUpload(blob)
    handleClose()
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setAccountsApplications(prev => ({ ...prev, src: reader.result }))
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // If you setState the crop in here you should return false.
  const onImageLoaded = image => {
    setImageRef(image)
  }

  const onCropComplete = crop => {
    makeClientCrop(crop)
  }

  const onCropChanges = crop => {
    setAccountsApplications(prev => ({ ...prev, crop: crop }))
  }
  const fileUpload = e => {
    const url = props.urlUpload
    const formData = new FormData()
    if (e) {
      formData.append(e.name, e, e.name)
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      axios.post(url, formData, config).then(response => {
        props.changePhoto(
          response.data.filePath,
          response.data.resolvedFilePath
        )
      })
    }
  }

  async function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      await getCroppedImg(imageRef, crop, "newFile.jpeg")
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas")
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise(resolve => {
      canvas.toBlob(blobs => {
        if (!blobs) {
          return
        }
        blobs.name = fileName
        setBlob(blobs)
        let fileUrl = window.URL.createObjectURL(blobs)

        window.URL.revokeObjectURL(fileUrl)

        resolve(fileUrl)
      }, "image/jpeg")
    })
  }

  return (
    <div>
      <button
        htmlFor="files"
        onClick={handleClickOpen}
        className="selectImage position update-photo"
      >
        {t("main.userProfileMap.updatePhoto")}
      </button>

      <Dialog
        open={accountsApplications.open}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{t("main.userProfileMap.selectImage")}</DialogTitle>
        <DialogContent>
          <input
            type="file"
            ref={fileInput}
            style={{ display: "none" }}
            onChange={onSelectFile}
            multiple
          />

          {accountsApplications.src && (
            <ReactCrop
              src={accountsApplications.src}
              crop={accountsApplications.crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChanges}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("schoolAdmin.close")}
          </Button>
          <Button onClick={() => fileInput.current.click()}>
            {accountsApplications.src === null
              ? t("schoolAdmin.uploadPhoto")
              : t("schoolAdmin.changePhoto")}
          </Button>
          {accountsApplications.src !== null ? (
            <Button onClick={savePhotoUpdate}>
              {t("schoolAdmin.savePhoto")}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CropperPhoto
