import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "../../http/axios"
import { useCurrentUser } from "../../components/CurrentUserContext"
import UpdatePhotoIcon from "../../svg/update-photo-icon.inline.svg"
import CropperPhoto from "../../components/CropperPhoto"
import { notification } from "antd"

const UserProfileProfileInfoSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const { currentUser, fetchCurrentUser, userLoading } = useCurrentUser()

  const [unableToSave, setUnableToSave] = useState(false)

  const [currentAccounts, setCurrentAccounts] = useState({})
  const [allInputValue, setAlInputValue] = useState({
    firstname: "",
    lastname: "",
    country: "",
    userEmail: "",
    phoneNumber: "",
    userImage: "",
    imageUrl: "",
  })

  useEffect(async () => {
    if (currentUser) {
      setCurrentAccounts(currentUser)
      setAlInputValue({
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        country: currentUser.country,
        userEmail: currentUser.email,
        phoneNumber: currentUser.phone,
        userImage: currentUser.resolvedImageUrl,
      })
    }
  }, [userLoading])

  const handleChange = e => {
    setUnableToSave(false)

    const { name, value } = e.target
    setAlInputValue(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const changePhoto = (filePath, resolvedFilePath) => {
    setAlInputValue(prevState => ({
      ...prevState,
      userImage: resolvedFilePath,
      imageUrl: filePath,
    }))
  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  function handleFormSubmit(e) {
    e.preventDefault()

    const resultItem = {
      email: allInputValue.userEmail,
      phone: allInputValue.phoneNumber,
      country: allInputValue.country,
      firstname: allInputValue.firstname,
      lastname: allInputValue.lastname,
      imageUrl: allInputValue.imageUrl
        ? allInputValue.imageUrl
        : currentUser.imageUrl,
      languageCode: lang,
      id: currentAccounts.id,
    }
    axios
      .put(`/accounts/${currentAccounts.id}`, resultItem)
      .then(response => {
        fetchCurrentUser(true)
        openNotification("topRight")
        setUnableToSave(false)
      })
      .catch(error => {
        if (error.response.status !== 200) {
          setUnableToSave(true)
        }
      })
  }

  return (
    <div className="user-profile-info-links-wrapper container">
      <div className="user-photo-wrapper f-ac f-js row">
        {/* UPDATE PHOTO */}
        <div className="update-photo-wrapper f-ac col-12">
          {allInputValue.userImage ? (
            <img
              src={allInputValue.userImage}
              height={48}
              width={48}
              alt="imgCropper"
            />
          ) : (
            <div className="previewText">
              {" "}
              {t("main.userProfileMap.selectImage")}
            </div>
          )}
          <UpdatePhotoIcon className="update-photo-icon updatePhoto" />
          <div className="update-photo-wrapper-dialog">
            <CropperPhoto
              urlUpload={"/accounts/schools/files/upload"}
              changePhoto={changePhoto}
            />{" "}
          </div>
        </div>
      </div>
      <div className="user-info-fields-wrapper row">
        <form onSubmit={handleFormSubmit}>
          <div className="user-fields-wrapper">
            <div className="input-group">
              <input
                onChange={handleChange}
                value={allInputValue.firstname}
                type="text"
                name="firstname"
                required
              />
              <label>{t("main.userProfileMap.firstName")}</label>
            </div>
            <div className="input-group">
              <input
                onChange={handleChange}
                value={allInputValue.lastname}
                type="text"
                name="lastname"
                required
              />
              <label>{t("main.userProfileMap.lastName")}</label>
            </div>
            <div className="input-group">
              <input
                onChange={handleChange}
                type="text"
                value={allInputValue.country || ""}
                name="country"
                required
              />
              <label>{t("main.userProfileMap.country")}</label>
            </div>
            <div className="input-group">
              <input
                onChange={handleChange}
                type="email"
                value={allInputValue.userEmail}
                name="userEmail"
                required
              />
              <label>{t("main.userProfileMap.email")}</label>
            </div>
            <div className="input-group">
              <input
                onChange={handleChange}
                type="tel"
                name="phoneNumber"
                value={allInputValue.phoneNumber}
                required
              />
              <label>{t("main.userProfileMap.phoneNumber")}</label>
            </div>

            {unableToSave && (
              <span className={`error`}>{t("main.header.unableToSave")}</span>
            )}

            <button
              className="show-all-button save-info-btn user-info-save-btn"
              type="submit"
            >
              <div>{t("main.userProfileMap.save")}</div>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserProfileProfileInfoSection
