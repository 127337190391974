import React, { useEffect, useState } from "react"
import axios from "../../http/axios"
import { useCurrentUser } from "../../components/CurrentUserContext"

import DeleteIcon from "../../svg/delete-icon.inline.svg"

import PlusIcon from "../../svg/plus-icon.inline.svg"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { DATE_FORMAT_API } from "../../constants/constants"
import { notification } from "antd"

const UserProfileStudentInfoSection = () => {
  const dateFormat = DATE_FORMAT_API
  const { t } = useTranslation()

  const { currentUser, userLoading } = useCurrentUser()

  const [currentAccounts, setCurrentAccounts] = useState({})

  const [unableToSave, setUnableToSave] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)

  const [accountsApplications, setAccountsApplications] = useState([
    {
      fullname: "",
      dateOfBirth: "",
      admissionAge: "",
    },
  ])

  useEffect(() => {
    getChild()
  }, [userLoading])

  const getChild = async () => {
    if (currentUser && currentUser.id) {
      setCurrentAccounts(currentUser)
      await axios.get(`accounts/${currentUser.id}/children`).then(res => {
        const resultArray = res.data.map(a => {
          const resultData = moment(a.dateOfBirth).format(dateFormat)
          return { ...a, dateOfBirth: resultData }
        })
        setAccountsApplications(resultArray)
      })
    }
  }

  const openNotification = placement => {
    notification.info({
      message: t("school.feedback.dataSaved"),
      placement,
    })
  }

  const handleRemoveItem = (itemId, index) => {
    let array = [...accountsApplications]
    let resultArray
    if (itemId) {
      axios.delete(`/accounts/children/${itemId}`)
      resultArray = array.filter((_, i) => i !== index)
    } else {
      resultArray = array.filter((_, i) => i !== index)
    }
    setAccountsApplications(resultArray)
  }

  function handleFormSubmit(e) {
    e.preventDefault()
    setDisabledButton(true)
    const resultArray = accountsApplications.map(a => {
      const resultData = a.dateOfBirth
      return { ...a, dateOfBirth: resultData }
    })
    axios
      .post(`/accounts/${currentAccounts.id}/children/save`, {
        children: resultArray,
      })
      .then(() => {
        getChild()
        openNotification("topRight")
        setDisabledButton(false)
        setUnableToSave(false)
      })
      .catch(error => {
        setDisabledButton(false)
        if (error.response.status !== 200) {
          setUnableToSave(true)
        }
      })
  }

  const handleChange = (e, index) => {
    setUnableToSave(false)
    const { name, value } = e.target
    const list = [...accountsApplications]
    list[index][name] = value
    setAccountsApplications(list)
  }

  const handleAddItem = () => {
    setAccountsApplications(prevState => {
      return [...prevState, { fullname: "", dateOfBirth: "", admissionAge: "" }]
    })
  }

  return (
    <>
      <div className="user-profile-info-links-wrapper container">
        <div className="user-info-fields-wrapper student-info-fields">
          <form onSubmit={handleFormSubmit} className="child1">
            {accountsApplications &&
              accountsApplications.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="student-fields-wrapper row mb-3">
                      <div className="col-md-12 col-lg-3">
                        <div className="input-group">
                          <input
                            type="text"
                            onChange={event => handleChange(event, index)}
                            value={item.fullname}
                            name="fullname"
                            required
                          />
                          <label>{t("main.header.childFullName")}</label>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="input-group">
                          <input
                            type="date"
                            name="dateOfBirth"
                            required
                            onChange={event => handleChange(event, index)}
                            value={item.dateOfBirth}
                            placeholder={null}
                          />

                          <label className="firefox-label">
                            {t("main.header.dateBirth")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="input-group">
                          <input
                            type="number"
                            onChange={event => handleChange(event, index)}
                            value={item.admissionAge}
                            name="admissionAge"
                            required
                          />
                          <label>{t("main.header.planningSchool")}</label>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-2 d-flex align-items-center">
                        <DeleteIcon
                          onClick={() => handleRemoveItem(item.id, index)}
                          className="delete-icon"
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            <div
              onClick={() => handleAddItem()}
              className="add-child-wrapper f-js f-ac p-20"
            >
              <PlusIcon className="plus-icon" />
              <a href="#" className="user-nav-bar-option active">
                {t("main.userProfileMap.addChild")}
              </a>
            </div>

            {unableToSave && (
              <span className={`error`}>{t("main.header.unableToSave")}</span>
            )}

            <button
              disabled={disabledButton}
              className="show-all-button save-info-btn students-info-btn school-admin-info-btn"
              type="submit"
            >
              <div>{t("main.userProfileMap.save")}</div>
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default UserProfileStudentInfoSection
